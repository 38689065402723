/* ==========================================================================
   Night theme skin
   ========================================================================== */

/* Colors */
$base-color: #1f1f1f !default;
$text-color: #eaeaea !default;
$accent-color: #365baa !default;
$background-color: #000000 !default;
$code-background-color: #fff !default;
$border-color: rgba($text-color, 0.5) !default;
$overlay-color: #fff !default;
