/* ==========================================================================
   PRINT STYLES
   ========================================================================== */

@media print {

  [hidden] {
    display: none;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    height: auto;
    min-height: auto !important;
    font-size: 16px;
  }

  body {
    margin: 0;
    height: auto;
    background: #fff !important;
    color: #000 !important;
    font-size: 1rem;
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.75rem;
    color: #000;
    line-height: 1.2;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  a {
    background: none;
    color: #000;
    text-decoration: underline;
    word-wrap: break-word;
  }

  table {
    border-collapse: collapse;
  }

  thead {
    display: table-header-group;
  }

  table,
  th,
  td {
    border-bottom: 1px solid #000;
  }

  td,
  th {
    padding: 8px 16px;
  }

  img {
    display: block;
    max-width: 100% !important;
    border: 0;
    vertical-align: middle;
  }

  hr {
    height: 0;
    margin: 2.25rem 0;
    padding: 0;
    border: 0;
    border-bottom: 2px solid #bbb;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0;
    margin-bottom: 0.75rem;
  }

  abbr[title],
  acronym[title] {
    border: 0;
    text-decoration: none;
  }

  table,
  blockquote,
  pre,
  code,
  figure,
  li,
  hr,
  ul,
  ol,
  a,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  h4,
  p,
  a {
    orphans: 3;
    widows: 3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  h1 + p,
  h2 + p,
  h3 + p {
    page-break-before: avoid;
  }

  img {
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: avoid;
  }

  pre {
    white-space: pre-wrap !important;
    word-wrap: break-word;
  }

  a[href^='http://']::after,
  a[href^='https://']::after,
  a[href^='ftp://']::after {
    font-size: 80%;
    content: ' (' attr(href) ')';
  }

  abbr[title]::after,
  acronym[title]::after {
    content: ' (' attr(title) ')';
  }

  .wrapper {
    min-height: auto !important;
  }

  #main {
    max-width: 100%;
  }

  .inner {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .intro-actions .btn {
    color: #fff;
    background: #000;
  }

  .page-break,
  .page-break-before {
    page-break-before: always;
  }

  .page-break-after {
    page-break-after: always;
  }

  .no-print {
    display: none;
  }

  a.no-reformat::after {
    content: '';
  }

  abbr[title].no-reformat::after,
  acronym[title].no-reformat::after {
    content: '';
  }

  .btn {
    background: none;
    color: #000;
  }

/*
   Hide the following elements on print
   ========================================================================== */

  .skip-links,
  .sidebar-toggle-wrapper,
  .sidebar,
  .title-area,
  .pager,
  .site-footer
  {
    display: none !important;
    height: 1px;
  }
}
