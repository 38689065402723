/* ==========================================================================
   Site-wide
   ========================================================================== */

.site-title {
  @include fluid-type($min-vw, $max-vw, 20px, 24px);
  margin: 0;
  padding: 1.8125rem 1rem;
  padding-right: calc(
    10vw + (2 * #{$navicon-width})
  ); /* make room for sidebar toggle */
  font-family: $base-font-family;
  font-weight: bold;
  line-height: 1;

  @include breakpoint($medium) {
    padding-left: 2rem;
  }

  @include breakpoint($large) {
    padding-left: 5vw;
  }

  a {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    min-height: $site-image-height;
    color: $text-color;
    text-decoration: none;
  }
}

.site-logo {
  //width: $site-image-width;
  height: $site-image-height;
  margin-right: 0.5rem;
  // border-radius: 50%;
}

.more-link {
  font-weight: bold;
  text-decoration: none;
}

.back-to-top {
  display: block;
  color: lighten($text-color, 50%);
  font-size: 0.75em;
  text-transform: uppercase;
  text-align: right;
  text-decoration: none;
}

.taxonomy-section {
  margin-bottom: 2em;
  padding-bottom: 1em;

  &:not(:last-child) {
    border-bottom: solid 1px $border-color;
  }

  + .taxonomy-section {
    margin-top: 2em;
  }
}

.taxonomy-title {
  @include fluid-type($min-vw, $max-vw, 28px, 32px);
  margin-bottom: 0.5em;
  color: lighten($text-color, 60%);
  font-family: $base-font-family;
}

.taxonomy-count {
  color: lighten($text-color, 50%);
}

.taxonomy-index {
  display: grid;
  grid-column-gap: 2em;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-size: 0.9em;
  list-style: none;

  @include breakpoint($large) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.25em 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid $border-color;

    &:hover {
      color: $accent-color;
    }
  }

  + .taxonomy-section {
    margin-top: 3em;
  }
}
